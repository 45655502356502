<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8" lg="6" xl="4">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm class="needs-validation" v-on:submit.prevent="authLogin()">
                  <h1>Вход</h1>
                  <p class="text-muted">Вход в аккаунт</p>
                  <CInput
                    placeholder="Логин"
                    autocomplete="username email"
                    v-model="login"
                    :is-valid="isValid('login')"
                    :invalid-feedback="getErrorMessage('login')"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user"/>
                    </template>
                  </CInput>
                  <CInput
                    placeholder="Пароль"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                    :is-valid="isValid('password')"
                    :invalid-feedback="getErrorMessage('password')"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked"/>
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton type="submit" color="primary" class="px-4" :disabled="clicked">Войти</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="12">
                      <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
                      <p v-if="!errorMessage" class="text-danger">&nbsp;</p>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: 'Login',
  data() {
    return {
      login: '',
      password: '',
      clicked: false,
      errorMessage: null,
      validateModels: null,
    };
  },
  methods: {
    async authLogin() {
      this.clicked = true;
      this.errorMessage = null;
      this.validateModels = null;
      let result = await this.$http.post('/auth/login', {
        'login': this.login,
        'password': this.password,
      });

      switch (result.status) {
        case 200:
          await localStorage.setItem('token', result.data.data.token);
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
          await this.$router.push('/');
          break;
        case 422:
          this.validateModels = result.data.errors;
          this.errorMessage = result.data.message;
          break;
        case 400:
          this.errorMessage = result.data.message;
          break;
        default:
          break;
      }

      this.clicked = false;
    },
    isValid(field) {
      if (this.validateModels === null) {
        return null;
      }

      if (this.validateModels[field] === undefined) {
        return null;
      }

      return false;
    },
    getErrorMessage(field) {
      if (this.validateModels === null) {
        return null;
      }

      if (this.validateModels[field] === undefined) {
        return null;
      }

      return this.validateModels[field].join('<br>');
    }
  },
}
</script>
